// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-regulamin-strony-js": () => import("./../../../src/pages/regulamin-strony.js" /* webpackChunkName: "component---src-pages-regulamin-strony-js" */),
  "component---src-templates-news-post-template-js": () => import("./../../../src/templates/news-post-template.js" /* webpackChunkName: "component---src-templates-news-post-template-js" */),
  "component---src-templates-oferta-podstrona-js": () => import("./../../../src/templates/oferta-podstrona.js" /* webpackChunkName: "component---src-templates-oferta-podstrona-js" */)
}

